import { useDate } from '@/Shared/Composables/useDate.js'

export const getDatesFromTodayOnwards = (length = 29, format = 'ddd D MMMM YYYY') => {
  const dayjs = useDate()

  return Array.from({ length: length }, (_, i) => (i ? dayjs().add(i, 'day').format(format) : 'Today'))
}

export const getFormattedTimeDifference = (to, from = null, format = 'HH:mm') => {
  const dayjs = useDate()

  const fromTime = from ? dayjs(from, format) : dayjs()
  const toTime = dayjs(to, format)

  const diff = toTime.diff(fromTime)
  const duration = dayjs.duration(diff)

  // Format the duration with custom logic for singular/plural forms
  const hours = duration.hours()
  const minutes = duration.minutes()

  let formattedDiff = ''

  if (hours > 0) {
    formattedDiff += `${hours}h`
  }

  if (minutes > 0) {
    formattedDiff += `${formattedDiff ? ' ' : ''}${minutes} ${minutes === 1 ? 'min' : 'mins'}`
  }

  return formattedDiff
}

export const getCurrentMonthYear = () => {
  const dayjs = useDate()

  return dayjs().format('MMMM YYYY')
}

export const getCurrentTime = () => {
  const dayjs = useDate()

  return dayjs().format('h:mm a')
}
